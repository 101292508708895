import Vue from 'vue'
import Router from 'vue-router'
import hooks from './hooks'

Vue.use(Router)

const routes = [{
    path: '/',
    name: 'payment',
    component: () => import('@/views/payment/pay.vue'),
    meta: {
      title: '停车缴费'
    }
  },
  {
    path: '/coupon-provide',
    name: 'provide',
    component: () => import('@/views/coupon/provide.vue'),
    meta: {
      title: '优惠券生成'
    }
  },
  {
    path: '/provide-suc',
    name: 'provide-suc',
    component: () => import('@/views/coupon/provideSuc.vue'),
    meta: {
      title: '发放成功'
    }
  },
  {
    path: '/coupon-select',
    name: 'select',
    component: () => import('@/views/payment/couponSelect.vue'),
    meta: {
      title: '选择优惠券'
    }
  },
  {
    path: '/pay-success',
    name: 'success',
    component: () => import('@/views/payment/success.vue'),
    meta: {
      title: '支付成功'
    }
  },
  {
    path: '/device-pay',
    name: 'device-pay',
    component: () => import('@/views/devicePay/index.vue'),
    meta: {
      title: '通道付'
    }
  },
  {
    path: '/pre-pay',
    name: 'pre-pay',
    component: () => import('@/views/prePay/index.vue'),
    meta: {
      title: '场内预付'
    }
  },
  {
    path: '/road-pay',
    name: 'road-pay',
    component: () => import('@/views/roadPay/index.vue'),
    meta: {
      title: '停车缴费'
    }
  },
  {
    path: '/pay-record',
    name: 'pay-record',
    component: () => import('@/views/roadPay/payRecord/index.vue'),
    meta: {
      title: '缴费记录'
    }
  },
  {
    path: '/park-record',
    name: 'park-record',
    component: () => import('@/views/roadPay/parkRecord/index.vue'),
    meta: {
      title: '停车记录'
    }
  },
  // 贵阳路侧出场支付
  {
    path: '/to-pay',
    name: 'to-pay',
    component: () => import('@/views/roadPay/toPay/index.vue'),
    meta: {
      title: '支付费用'
    }
  },
  // 路侧欠费订单
  {
    path: '/arrears',
    name: 'arrears',
    component: () => import('@/views/roadPay/arrears/index.vue'),
    meta: {
      title: '欠费订单'
    }
  },
  {
    path: '/invoicing',
    name: 'invoicing',
    component: () => import('@/views/invoicing/invoicingInfo.vue'),
    meta: {
      title: '开发票',
      needLogin: true,
      hiddenLArrow: true
    }
  },
  {
    path: '/pay-error',
    name: 'error',
    component: () => import('@/views/payment/payError.vue'),
    meta: {
      title: '提示'
    }
  },
  {
    path: '/car-in',
    name: 'car-in',
    component: () => import('@/views/noPlate/in.vue'),
    meta: {
      title: '入场成功'
    }
  },
  // {
  //   path: '/car-out',
  //   name: 'car-out',
  //   component: () => import('@/views/noPlate/out.vue'),
  //   meta: {
  //     title: '车辆出场'
  //   }
  // },
  {
    path: '/out-suc',
    name: 'out-suc',
    component: () => import('@/views/noPlate/outSuc.vue'),
    meta: {
      title: '出场成功'
    }
  },
  // 智慧停管平台H5
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/stopPipe/privacyPolicy.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/channel-video',
    name: 'channel-video',
    component: () => import('@/views/stopPipe/channelVideo.vue'),
    meta: {
      title: '通道视频'
    }
  },
  {
    path: '*',
    name: '404',
    component: ()=>import('@/components/error.vue'),
    meta: {
      title: '404'
    }
  }
]
const router = new Router({
  mode: 'hash',
  routes: routes
})
Object.values(hooks).forEach(hook => {
  router.beforeEach(hook.bind(router))
})
export default router
//贵阳入场支付 https://management.cheyifu2016.com/pm-pay/#/to-pay?&recordId=
//出场欠费支付 https://management.cheyifu2016.com/pm-pay/#/?orderNo=234&userId=23&payUse=NEW_ROAD  //new_road为贵阳，road为原来路侧
//路侧开发票 https://management.cheyifu2016.com/pm-pay/#/invoicing?roadRecordId=123&type=new
