import $http from '../utils/http'

// 无牌车入场
export const unlicensed = (data) => {
  return $http.request({
    url: `/inout/noPlateNew/unlicensedInNew`,
    method: 'post',
    data
  })
}
// 无牌车出场
export const unlicensedOut = (data) => {
  return $http.request({
    url: `/inout/noPlateNew/unlicensedOutNew`,
    method: 'post',
    data
  })
}
