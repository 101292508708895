<template>
 <div id="app">
  <!-- <van-nav-bar
   :title="$route.meta.title"
   :left-arrow="$route.meta.showLeft"
   :right-text="$route.meta.rightText"
   @click-left="handleLeft"
   @click-right="onClickRight"
  /> -->
  <router-view v-wechat-title="$route.meta.title" />
 </div>
</template>
<script>
import { browser } from './utils/index'
export default {
 created() {
 },
 methods: {
  // handleLeft() {
  //  if (!this.$route.meta.showLeft) return
  //  const url = this.$route.meta.leftUrl
  //  this.$router.push(url)
  // },
  // onClickRight() {
  //  const text = this.$route.meta.rightText
  //  if (text === '完成' || text === '关闭') {
  //   if (browser.versions.wx) {
  //    //微信
  //    WeixinJSBridge.invoke('closeWindow', (res) => {
  //     if (res.err_msg === 'close_window:ok') {
  //     } else {
  //      this.$toast(res.strError)
  //     }
  //    })
  //   }
  //   if (browser.versions.aliPay) {
  //    //支付宝
  //    AlipayJSBridge.call('closeWebview')
  //   }
  //  }
  // },
 },
}
</script>
<style lang="stylus" scoped>
.header {
 position: relative;
 height: 44px;
 line-height: 44px;
 text-align: center;
 font-size: 18px;
 font-weight: 700;
 background: #fff;
 color: #333;

 .back {
  position: absolute;
  left: 15px;
  top: 13px;
  content: '';
  width: 13px;
  height: 13px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  transform: rotate(-45deg);
 }
}
</style>
