import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router/index'
import createPersistedState from 'vuex-persistedstate'
import {
  connectPay,
  connectAliPay,
  connectWx,
} from './api/payment'
import {
  payRoadOrderWx
} from './api/road'
import {
  unlicensed,
  unlicensedOut
} from './api/noPlate'
import {
  userIssueRecord
} from './api/coupon'
import router from '@/router/index'

Vue.use(Vuex)

const state = {
  appId: '',
  recordId: '',
  projectId: '',
  openid: '',
  errorMsg: '', //错误信息
  jsonParam: '',
  payWay: '',
  couponList: '', //优惠券列表
  couponNum: '', //优惠券总张数
  couponUseListDtos: [], //优惠券使用id和张数
  paymentMethod: '', //支付渠道 
  code: '', //微信code
  noPlateInfo: '', //无牌车信息
  parkingId: '', //车场id
  orders: [], //路侧支付已选择订单

}
const mutations = {
  SET_PARAM(state, param) { //设置通道付请求参数
    state.jsonParam = param
  },
  SET_ERROR_MSG(state, msg) { //设置错误提示信息
    state.errorMsg = msg
  },
  SET_APP_ID(state, appId) { //设置appId
    state.appId = appId
  },
  SET_OPEN_ID(state, openid) {
    state.openid = openid
  },
  SET_PAY_WAY(state, payWay) {
    state.payWay = payWay
  },
  SET_PROJECT_ID(state, id) {
    state.projectId = id
  },
  SET_COUPON_LIST(state, list) {
    state.couponList = list
  },
  SET_COUPON_TOTAL(state, total) {
    state.couponNum = total
  },
  SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload
  },
  SET_CODE(state, code) {
    state.code = code
  },
  SET_COUPON_USE_LIST(state, payload) {
    if (Array.isArray(payload)) {
      state.couponUseListDtos = payload
      return
    }
    let ind
    let res = state.couponUseListDtos.some((item, index) => {
      if (item.merchantCouponId == payload.merchantCouponId) {
        ind = index
        return true
      }
      return false
    })
    if (res) {
      state.couponUseListDtos.splice(ind, 1, payload)
    } else {
      state.couponUseListDtos.push(payload)
    }
  },
  SET_NO_PLATE_INFO(state, info) {
    state.noPlateInfo = info
  },
  SET_ORDER_INFO(state, data) {
    if(Array.isArray(data.order)){
      // data.order为数组(手持机打印欠费小票情况)
      state.orders = [...data.order]
    }else{
    state.parkingId = data.order.parkingId
      state.orders = [{
        ...data.order
      }]
    }
  },
  UPDATE_ORDER_INFO(state, data) {
    if (Array.isArray(data)) {
      state.orders = data
    } else {
      if (data.checked) {
        // 选中
        state.orders.push(data.order)
      } else {
        // 取消选择
        state.orders = state.orders.filter(item => item.id !== data.order.id)
      }
    }
  },
  RESET_ORDERS(state) {
    state.orders = []
    state.parkingId = ''
  },
}
async function pay_callback(next, commit) {
  if (store.state.jsonParam.payUseEnum === 'ROAD_PAY') {
    // 手持机支付
    if (store.state.payWay == 'WE_CHAT') {
      // 微信
      connectWx().then(res => {
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            'appId': res.appId, //公众号名称，由商户传入     
            'timeStamp': res.timeStamp, //时间戳，自1970年以来的秒数     
            'nonceStr': res.nonceStr, //随机串     
            'package': res.packageStr,
            "signType": res.signType, //微信签名方式：     
            "paySign": res.paySign //微信签名 
          },
          (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {} else {
              commit('SET_ERROR_MSG', res.strError || '订单支付失败')
              router.replace('/pay-error')
            }
          })
      }, res => {
        commit('SET_ERROR_MSG', res.strError)
        router.replace('/pay-error')
      }).catch(res => {
        commit('SET_ERROR_MSG', res.strError ? res.strError : '下单失败')
        router.replace('/pay-error')
      })
    } else {
      // 支付宝
      connectAliPay().then(res => {
        window.location.href = res.payUrl
      }, res => {
        commit('SET_ERROR_MSG', res.strError || '订单支付失败')
        router.replace('/pay-error')
      })
    }
    return
  }
  // 新的路侧支付
  if (store.state.jsonParam.payUseEnum === 'NEW_ROAD') {
    // 手持机支付
    if (store.state.payWay == 'WE_CHAT') {
      // 微信
      payRoadOrderWx({
        payWay: 1,
        payType: 1, //1，H5，2小程序
        userId:store.state.jsonParam.userId,
        code: store.state.code,
        orderNoList: store.state.orders.map(item => item.orderNo)
      }).then(res => {
        if (res.data.returnCode == 'SUCCESS') {
          res = res.data.payment
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              'appId': res.appId, //公众号名称，由商户传入     
              'timeStamp': res.timeStamp, //时间戳，自1970年以来的秒数     
              'nonceStr': res.nonceStr, //随机串     
              'package': res.packageStr,
              "signType": res.signType, //微信签名方式：     
              "paySign": res.paySign //微信签名 
            },
            (res) => {
              if (res.err_msg === "get_brand_wcpay_request:ok") {} else {
                commit('SET_ERROR_MSG', res.strError || '订单支付失败')
                router.replace('/pay-error')
              }
            })
        } else {
          commit('SET_ERROR_MSG', res.data.returnMsg)
          router.replace('/pay-error')
        }

      }, res => {
        commit('SET_ERROR_MSG', res.msg ? res.msg : '支付失败')
        router.replace('/pay-error')
      }).catch(res => {
        commit('SET_ERROR_MSG', res.msg ? res.msg : '下单失败')
        router.replace('/pay-error')
      })
    } else {
      // 支付宝
      payRoadOrderWx({
        payWay: 2,
        userId:store.state.jsonParam.userId,
        orderNoList: store.state.orders.map(item => item.orderNo)
      }).then(res => {
        if (res.data.code == '10000') {
          window.location.href = res.data.qrCode
        } else {
          commit('SET_ERROR_MSG', res.data.msg || '下单失败')
          router.replace('/pay-error')
        }
      }, res => {
        commit('SET_ERROR_MSG', res.msg || '下单失败')
        router.replace('/pay-error')
      })
      // commit('SET_ERROR_MSG', '暂不支持支付宝支付')
      // router.replace('/pay-error')
    }
    return
  }

  // 车场支付和充值
  let param = null;
  if (store.state.jsonParam.orderNo) {
    // 通道付和场内预付
    param = {
      projectId: store.state.projectId,
      parkingId: store.state.jsonParam.parkingId ? store.state.jsonParam.parkingId : null, //新加场内预付抬杆功能
      plate: store.state.jsonParam.plate ? store.state.jsonParam.plate : null, //新加场内预付抬杆功能
      openid: store.state.openid,
      payUse: store.state.jsonParam.payUseEnum,
      payWay: store.state.payWay,
      orderNo: store.state.jsonParam.orderNo,
      payType: 1, //1，H5，2小程序
      code: store.state.code,
      paymentMethod: store.state.paymentMethod,
      appid: store.state.appId,
    }
  } else {
    // 会员充值等支付
    param = {
      openid: store.state.openid,
      fee: store.state.jsonParam.fee,
      color: (store.state.jsonParam.color && typeof (store.state.jsonParam.color) == 'object') ? store.state.jsonParam.color.value : null,
      outTime: store.state.jsonParam.outTime,
      parkingId: store.state.jsonParam.parkingId,
      payUse: store.state.jsonParam.payUseEnum,
      payWay: store.state.payWay,
      plate: store.state.jsonParam.plate,
      projectId: store.state.projectId,
      recordId: store.state.jsonParam.recordId,
      sentryBoxId: store.state.jsonParam.sentryBoxId,
      deviceId: store.state.jsonParam.deviceId,
      orderNo: store.state.jsonParam.orderNo,
      payType: 1, //1，H5，2小程序
      code: store.state.code,
      paymentMethod: store.state.paymentMethod,
      appid: store.state.appId,
    }
  }
  try {
    const res = await connectPay(param)
    if (res.data.returnCode == '10000') {
      //  支付宝成功
      window.location.href = res.data.payUrl
      return
    }
    if (res.data.returnCode !== '200' && res.data.returnCode !== 'SUCCESS' && res.data.returnCode !== '0000') {
      let msg = res.data.returnMsg
      if (res.data.returnMsg && res.data.returnMsg.includes('订单号重复')) {
        msg = "订单号已失效，请倒车重新识别！"
      }
      commit('SET_ERROR_MSG', msg ? msg : '订单支付失败')
      router.replace('/pay-error')
      return false
    }
    let payParam
    if (store.state.paymentMethod == 'useHnnx') {
      // 河南农信
      if (res.data.returnCode == '200') {
        if (store.state.payWay == 'ALI_PAY') {
          //支付宝支付
          AlipayJSBridge.call("tradePay", {
            tradeNO: res.data.advancePayRes.tradeNo
          }, function (result) {
            if (result.resultCode !== '9000') {
              // 失败
              commit('SET_ERROR_MSG', JSON.stringify(result) || '订单支付失败')
              router.replace('/pay-error')
            } else {
              AlipayJSBridge.call('closeWebview')
            }
          });
          return
        }
        // 微信河南农信
        payParam = {
          'appId': res.data.advancePayRes.appId, //公众号名称，由商户传入     
          'timeStamp': res.data.advancePayRes.timeStamp, //时间戳，自1970年以来的秒数
          'nonceStr': res.data.advancePayRes.charset, //随机串
          'package': res.data.advancePayRes.subject,
          "signType": res.data.advancePayRes.signType, //微信签名方式：
          "paySign": res.data.advancePayRes.sign //微信签名 
        }
      } else {
        commit('SET_ERROR_MSG', res.data.returnMsg || '订单支付失败')
        router.replace('/pay-error')
      }
    } else if (store.state.paymentMethod == 'useSxf') {
      //  随行付
      if (res.data.returnCode == '0000') {
        if (store.state.payWay == 'ALI_PAY') {
          //支付宝支付
          AlipayJSBridge.call("tradePay", {
            tradeNO: res.data.sxfResponseBean.respData.source
          }, function (result) {
            if (result.resultCode !== '9000') {
              // 失败
              commit('SET_ERROR_MSG', JSON.stringify(result) || '订单支付失败')
              router.replace('/pay-error')
            } else {
              AlipayJSBridge.call('closeWebview')
            }
          });
          return
        }
        // 微信随行付
        const sxfParam = res.data.sxfResponseBean.respData
        payParam = {
          'appId': sxfParam.payAppId, //公众号名称，由商户传入     
          'timeStamp': sxfParam.payTimeStamp, //时间戳，自1970年以来的秒数
          'nonceStr': sxfParam.paynonceStr, //随机串
          'package': sxfParam.payPackage,
          "signType": sxfParam.paySignType, //微信签名方式：
          "paySign": sxfParam.paySign //微信签名 
        }
      } else {
        commit('SET_ERROR_MSG', res.data.returnMsg || '订单支付失败')
        router.replace('/pay-error')
      }
    } else {
      payParam = {
        'appId': res.data.payment.appId, //公众号名称，由商户传入     
        'timeStamp': res.data.payment.timeStamp, //时间戳，自1970年以来的秒数
        'nonceStr': res.data.payment.nonceStr, //随机串
        'package': res.data.payment.packageStr,
        "signType": res.data.payment.signType, //微信签名方式：
        "paySign": res.data.payment.paySign //微信签名 
      }
    }
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', payParam,
      (res) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          // Toast('成功')
        } else {
          commit('SET_ERROR_MSG', res.strError || '订单支付失败')
          router.replace('/pay-error')
        }
      })
  } catch (res) {
    commit('SET_ERROR_MSG', res.data.returnMsg ? res.data.returnMsg : res.msg ? res.msg : '下单失败')
    router.replace('/pay-error')
  }
}

function WxJSBridge(next, commit) {
  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', (next, commit) => pay_callback(next, commit), false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', (next, commit) => pay_callback(next, commit))
      document.attachEvent('onWeixinJSBridgeReady', (next, commit) => pay_callback(next, commit))
    }
  } else {
    pay_callback(next, commit)
  }
}
const actions = {
  CONNECT_PAY: ({ // 支付
    commit
  }, next) => {
    pay_callback(next, commit)
  },
  GET_COUPON: ({ // 获取优惠券信息
    commit,
    dispatch,
    state
  }, next) => {
    userIssueRecord({
      orderNo: state.jsonParam.orderNo
    }).then(res => {
      if (res.data.couponList && res.data.couponList.length > 0) {
        commit('SET_COUPON_LIST', res.data.couponList)
        commit('SET_COUPON_TOTAL', res.data.residueCouponNumTotal)
        router.replace('/coupon-select')
      } else {
        //微信
        dispatch('CONNECT_PAY', next)
      }
    }, res => {
      commit('SET_ERROR_MSG', res.msg || '获取优惠券失败')
      router.replace('/pay-error')
    })
  },
  async UNLICENSED({
    state,
    commit
  }, next) {
    // 无牌车
    try {
      if (state.jsonParam.type == 1) {
        // 入场
        const params = {
          parkingId: state.jsonParam.parkingId,
          projectId: state.jsonParam.projectId,
          deviceId: state.jsonParam.deviceId,
          scanType: state.payWay == 'WE_CHAT' ? 1 : 2,
          appId: state.appId,
          code: state.code,
          sentryBoxId: state.jsonParam.sentryBoxId,
        }
        const res = await unlicensed(params)
        commit("SET_NO_PLATE_INFO", res.data)
        next('/car-in')
      } else {
        // 出场
        // next('/car-out')
        // const b = await new Promise((resolve, reject) => {
        //   setTimeout(function () {
        //     resolve('ok')
        //   }, 2000)
        // })
        // console.log(b)
        const resFee = await unlicensedOut({
          parkingId: state.jsonParam.parkingId,
          projectId: state.jsonParam.projectId,
          deviceId: state.jsonParam.deviceId,
          scanType: state.payWay == 'WE_CHAT' ? 1 : 2,
          appId: state.appId,
          code: state.code,
          sentryBoxId: state.jsonParam.sentryBoxId,
        })
        if (!resFee.data.amount || !resFee.data.orderNo) {
          // 无牌车0元订单
          commit("SET_ERROR_MSG", '未获取到订单信息')
          router.replace('/pay-error')
          return
        }
        commit('SET_PARAM', {
          ...resFee.data,
          fee: resFee.data.amount,
          projectId: state.jsonParam.projectId,
          deviceId: state.jsonParam.deviceId,
          payUse: 'DEVICE_PAY',
          payUseEnum: 'DEVICE_PAY',
          type: 3,
        })
        if (store.state.paymentMethod == 'useHnnx') {
          // 河南农信无牌车
          window.location.href = `https://wxbank.hnnx.com/mer/payOut/getAuthCode.do?authCallBackUrl=${process.env.VUE_APP_REDIRECT_URL}`
        } else {
          window.location.href = `${process.env.VUE_APP_REDIRECT_URL}?payUse=NO_PLATE`
        }
      }
    } catch (res) {
      commit('SET_ERROR_MSG', res.msg ? res.msg : state.jsonParam.type == 1 ? '无牌车入场失败' : '未查询到入场信息')
      next('/pay-error')
    }

  }
}
const store = new Vuex.Store({
  plugins: [createPersistedState({
    key: process.env.VUE_APP_STORE,
  })],
  state,
  mutations,
  actions
})

export default store