import axios from 'axios' // 基于promise
import {
  Toast
} from 'vant'
// axios可以配配置拦截器 我可以给实例增加多个拦截器
// axios 实例的唯一性 ，我可以给，每个请求 独立增加拦截器

// 开发的时候 localhost  /xxx
class AjaxRequest {
  constructor() {
    // development production
    this.baseURL = process.env.VUE_APP_BASE_API // 基础路径
    this.timeout = 30000 // 超时时间1
    // message组件
    this.queue = {}
  }
  setInterceptor(instance, url) {
    instance.interceptors.request.use((config) => { // 请求拦截
      instance.toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      return config
    }, err => {
      return Promise.reject(err)
    })
    instance.interceptors.response.use((res) => { // 响应拦截
      instance.toast.clear()
      // 关闭loading
      // 可以对返回的状态码做各种匹配
      if (res.config.baseURL === process.env.VUE_APP_ROAD_API) {
        if (res.data.result === 1) {
          return res.data
        } else {
          return Promise.reject(res.data)
        }
      }else{
        if (res.data.code === 200 || res.data.result === 200) {
          return res.data
        } else {
          return Promise.reject(res.data)
        }
      }
     
    }, err => {
      instance.toast.clear()
      return Promise.reject(err)
    })
  }
  request(options) {
    const instance = axios.create()
    const config = {
      baseURL: process.env.VUE_APP_BASE_API,
      timeout: this.timeout,
      ...options,
    }
    this.setInterceptor(instance, options.url) // 给这个实例增加拦截功能
    return instance(config) // 返回的是一个promise
  }
}

export default new AjaxRequest