import $http from '../utils/http'

// 查询当前车的订单记录
export const getPayOrder = (params) => {
  return $http.request({
    url: `/roadparking/payorder/getPayOrder`,
    method: 'get',
    params
  })
}
// 手动出场页
export const outparkingInfo = (params) => {
  return $http.request({
    url: `/roadparking/road/outparkingInfo`,
    method: 'get',
    params
  })
}
// 安卓移动过来的欠费查询
export const getArrearsList = (params) => {
  return $http.request({
    url: `/roadparking/payorder/list`,
    method: 'get',
    params
  })
}
// 获取欠费订单
export const getArrearsPayOrder = (params) => {
  return $http.request({
    url: `/roadparking/payorder/getArrearsPayOrder`,
    method: 'get',
    params
  })
}
// 手动出场
export const carOutparkingByUser = (params) => {
  return $http.request({
    url: `/roadparking/road/carOutparkingByUser`,
    method: 'get',
    params
  })
}
// 停车记录
export const getRoadInOutParkingByPlate = (params) => {
  return $http.request({
    url: `/roadparking/roadInOutParking/getRoadInOutParkingByLicense`,
    method: 'get',
    params
  })
}
// 下单
export const payRoadOrderWx = (data) => {
  return $http.request({
    url: `/roadparking/payRoad/payRoadOrderWx`,
    method: 'post',
    data
  })
}

