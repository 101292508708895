import Vue from 'vue'
import {
  Col,
  Cell, 
  CellGroup,
  Checkbox,
  Row,
  Loading,
  Toast,
  Icon,
  NavBar,
  Button,
  RadioGroup,
  Radio,
  Popup,
  Field,
  Empty,
  Stepper,
  PullRefresh,
  List,
  Image,
  ImagePreview 
} from 'vant'

Vue.use(Loading)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Empty)
Vue.use(Col);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Stepper);
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Image)
Vue.use(ImagePreview)