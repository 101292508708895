import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import 'amfe-flexible'
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';
import './assets/css/reset.css'
import './vant'
import * as filters from './filters' // global filters
import VueWechatTitle from 'vue-wechat-title';

if (returnCitySN) {
  Vue.prototype.ip = returnCitySN.cip
  console.log(returnCitySN.cip)
}
Vue.use(VueWechatTitle);
Vue.config.productionTip = false
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vconsole from 'vconsole'

let vConsole = new Vconsole()

export default vConsole