import $http from '../utils/http'

// 优惠券发放
export const provideCoupon = (data) => {
  return $http.request({
    url: `/coupon/merchantCoupon/saveOrUpdate?merchantUuid=${data.merchantUuid}`,
    method: 'post',
    data
  })
}
// 查询可用优惠券

export const userIssueRecord = (params) => {
  return $http.request({
    url: '/coupon/userCoupon/userIssueRecord',
    method: 'post',
    params
  })
}
// 优惠券使用
export const couponUse = (data) => {
  return $http.request({
    url: '/coupon/userCoupon/couponUse',
    method: 'post',
    data
  })
}
// 支付成功
export const paySuccessInfo = (params) => {
  return $http.request({
    url: '/coupon/userCoupon/paySuccessInfo',
    method: 'post',
    params
  })
}