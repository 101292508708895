import {
  browser,
  paramValue
} from '../utils/index'
import {
  Toast
} from 'vant'
import store from '../store'
import {
  getAppId,
  getFee,
} from '@/api/payment'

const hooks = {
  getForm: (to, from, next) => {
    if (to.path !== '/') {
      next()
      return
    }
    if (!browser.versions.mobile) return Toast('请扫码支付') //非移动端设备打开
    // 判断来源
    const {
      payUse
    } = to.query
    if (payUse === 'DEVICE_PAY') {
      getFee({
        ...to.query,
        payUseEnum: payUse
      }).then(res => {
        // if (res.data.plateNo.startsWith('临')) {
        if (!res.data.amount || !res.data.orderNo) {
          // 临时车
          store.commit('SET_PARAM', {
            ...to.query,
            payUseEnum: 'NO_PLATE',
            type: 2, //无牌车
          })
        } else {
          store.commit('SET_PARAM', {
            ...res.data,
            fee: res.data.amount,
            ...to.query,
            payUseEnum: payUse
          })
        }
        store.commit('SET_PROJECT_ID', parseFloat(to.query.projectId))
        next()
      }, res => {
        // 走无牌车出场
        // 临时车
        console.log(res)
        store.commit('SET_PARAM', {
          ...to.query,
          payUseEnum: 'NO_PLATE',
          type: 2, //无牌车
        })
        store.commit('SET_PROJECT_ID', parseFloat(to.query.projectId))
        next()
      })

    } else if (payUse === 'TOP_UP' || payUse === 'MERCHANT_TOP_UP') {
      //  会员商户充值
      const params = JSON.parse(decodeURI(to.query.json))
      const obj = {
        ...params,
        payUseEnum: to.query.payUse
      }
      obj.fee = obj.fee
      store.commit('SET_PARAM', obj)
      store.commit('SET_PROJECT_ID', params.projectId)
      next()
    } else if (payUse === 'PARK_PREPAID' || payUse === 'ROAD_PAY' || payUse === 'NEW_ROAD' || (payUse === 'NO_PLATE' && to.query.type == 1)) {
      //  厂内预付手持机支付或无牌车入场
      if (payUse === 'NEW_ROAD' && to.query.orderNo) {
        // 新的手持机欠费小票
        const orderList = to.query.orderNo.split(',')  
        const data = {
          checked: true,
          order: orderList.map(item=>{
            return {
              id: '',
              fee: 0,
              parkingId: 0,
              orderNo: item,
            }
          })
        }
        store.commit('SET_ORDER_INFO', data)
      }
      store.commit('SET_PARAM', {
        ...to.query,
        payUseEnum: payUse
      })
      // 手持机无projectId
      const projectId = to.query.projectId ? parseFloat(to.query.projectId) : 0
      store.commit('SET_PROJECT_ID', projectId)
      next()
    } else {
      next()
    }
  },
  getCode: async (to, from, next) => {
    if (to.path !== '/') {
      next()
      return
    }
    const code = paramValue('code') || paramValue('auth_code')
    if (code) {
      // 已获取code
      store.commit('SET_CODE', code)
      if (store.state.jsonParam.payUseEnum === 'NO_PLATE' && store.state.jsonParam.type != 3) {
        // 无牌车入场和出场获取
        store.dispatch('UNLICENSED', next)
      } else {
        next()
      }
    } else {
      // 去获取code
      let appidRes
      if (store.state.jsonParam.payUseEnum !== 'ROAD_PAY' && store.state.jsonParam.payUseEnum !== 'NEW_ROAD' && store.state.jsonParam.type != 3) {
        // 非手持机
        try {
          // 获取appid和支付方式
          appidRes = await getAppId({
            projectId: store.state.projectId
          })
          if (appidRes.data) {
            store.commit('SET_APP_ID', appidRes.data.appId)
            store.commit('SET_PAYMENT_METHOD', appidRes.data.paymentMethod)
          } else {
            store.commit('SET_ERROR_MSG', '获取appid失败')
            next('/pay-error')
            return
          }
        } catch (e) {
          store.commit('SET_ERROR_MSG', '获取appid失败')
          next('/pay-error')
          return
        }
      }
      if (browser.versions.wx) { //微信
        store.commit('SET_PAY_WAY', 'WE_CHAT')
        if (store.state.jsonParam.payUseEnum === 'ROAD_PAY' || store.state.jsonParam.payUseEnum === 'NEW_ROAD') {
          // 手持机支付
          window.location.href =
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf71df1e7ec36be52&redirect_uri=${process.env.VUE_APP_REDIRECT_URL}?&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect`
        } else {
          if ((store.state.paymentMethod == 'useHnnx' && store.state.jsonParam.payUseEnum != 'NO_PLATE')) {
            // 河南农信
            window.location.href = `https://wxbank.hnnx.com/mer/payOut/getAuthCode.do?authCallBackUrl=${process.env.VUE_APP_REDIRECT_URL}`
          } else {
            window.location.href =
              `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${store.state.appId}&redirect_uri=${process.env.VUE_APP_REDIRECT_URL}?&response_type=code&scope=snsapi_base&state=123&connect_redirect=1#wechat_redirect`
          }
        }
      } else if (browser.versions.aliPay) { // 支付宝支付
        store.commit('SET_PAY_WAY', 'ALI_PAY')
        if (store.state.paymentMethod == 'useSxf' || store.state.jsonParam.payUseEnum === 'NO_PLATE') {
          // 随行付 和 无牌车
          window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021003156639529&scope=auth_base&redirect_uri=${process.env.VUE_APP_REDIRECT_URL}`
        } else if (store.state.paymentMethod == 'useHnnx') {
          // 河南农信
          window.location.href = `https://wxbank.hnnx.com/mer/payOut/getAuthCode.do?authCallBackUrl=${process.env.VUE_APP_REDIRECT_URL}`
        } else {
          // 默认支付
          if (store.state.jsonParam.payUseEnum === 'DEVICE_PAY' && store.state.jsonParam.type != 3) {
            // type = 3 为无牌车
            store.dispatch('GET_COUPON', next)
          } else {
            store.dispatch('CONNECT_PAY', next)
          }
        }
      } else {
        // 其他支付
        store.commit('SET_ERROR_MSG', '请用微信或支付宝扫码支付')
        next('/pay-error')
      }
    }
  }
}
export default hooks