/**
 * @param { String || Number} time
 */
export function formTime(time) {
  let str = ''
  time = Number(time).toFixed(2)
  if(time<1){
    return `${time}分`
  }
  const day = time / (60 * 24)
  const hours = time % (60 * 24) / 60
  const s = time % (60*24) %60
  if (day >= 1) {
    str = str + `${parseInt(day)}天`
  }
  if (hours >= 1) {
    str = str + `${parseInt(hours)}小时`
  }
  if (s >= 1) {
    str = str + `${s.toFixed(0)}分钟`
  }
  return str
}
//  分转元
export function filterFee(val) {
  if(!val) return '0.00'
  return (val / 100).toFixed(2)
 }
