import $http from '../utils/http'
import {
  paramValue
} from '../utils/index'
import store from '../store'

// // 通道付获取费用 
export const getFee = (data) => {
  return $http.request({
    url: '/charge/pay/getFee',
    method: 'post',
    data
  })
}
// 获取appid  
export const getAppId = (params) => {
  return $http.request({
    url: `/charge/pay/getAppid`,
    method: 'post',
    params,
  })
}
// 下单
export const connectPay = (data) => {
  return $http.request({
    url: '/charge/wx/toPay',
    method: 'post',
    data
  })
}
// 获取openId
export const getOpenidByCode = (params) => {
  return $http.request({
    url: '/charge/wx/obtainOpenid',
    method: 'get',
    params
  })
}

// 手持机支付连接微信
export const connectWx = () => {
  return $http.request({
    baseURL:process.env.VUE_APP_ROAD_API,
    url: 'openapi/wechatIsv',
    method: 'post',
    data: {
      code: paramValue('code'),
      orderId: store.state.jsonParam.orderNo,
    }
  })
}
// 手持机支付调用阿里支付
export const connectAliPay = (data) => {
  return $http.request({
    baseURL:process.env.VUE_APP_ROAD_API,
    url: 'openapi/alipayIsv',
    method: 'post',
    data: {
      orderId:store.state.jsonParam.orderNo,
    }
  })
}
// 河南农信阿里支付
export const hnnxAliPay = (params) => {
  return $http.request({
    baseURL:'https://openapi.alipay.com',
    url: '/gateway.do',
    method: 'post',
    params
  })
}

// 手持机通过recordId获取订单信息
export const roadRecordId = (params) => {
  return $http.request({
    baseURL:process.env.VUE_APP_ROAD_ORDER,
    url: '/roadparking/payorder/roadRecordId',
    method: 'get',
    params
  })
}