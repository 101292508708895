/**
 * 判断设备来源
 * @example 
 * @return 
 */
const browser = {
  versions: function () {
    const u = navigator.userAgent,
      app = navigator.appVersion;
    return {
      wx: u.indexOf('MicroMessenger') > -1, //是否是微信
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      aliPay: u.indexOf('AlipayClient') > -1,
      iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    }
  }()
}
/**
 * 取url参数
 * @example ?id=12345
 * @return 12345
 */
const paramValue = (key) => {
  var reg = new RegExp(`(?:^|&)${key}=([^&]+)(?=&|$)`),
    qs = location.search || location.hash;
  qs = qs.slice(qs.indexOf('?') + 1);
  return (key = qs.match(reg)) == null ? null : key[1];
};
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

const parseTime = (time, cFormat) => {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object' && time !== null) {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

const makeCode = (le) => {
  let res = ''
  const identifyCodes = '1234567890ABCDEFGHIGKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
   for (let i = 0; i < le; i++) {
     res += identifyCodes[
       Math.floor(Math.random() * 61)
     ]
   }
  return res
}
const filterTalkTime = (seconds) => {
  seconds?seconds:0
  let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
  seconds -= 3600 * hour;
  let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
  seconds -= 60 * min;
  let sec = seconds >= 10 ? seconds : '0' + seconds;
  return hour + ':' + min + ':' + sec;
}

export {
  browser,
  paramValue,
  parseTime,
  makeCode,filterTalkTime
}
